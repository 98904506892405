import { Colors, Icon, Tooltip } from '@blueprintjs/core';
import { Box, Flex } from 'grid-styled';
import * as numbro from 'numbro';
import * as React from 'react';
import { Scopes } from '../../auth';
import { Settings } from '../../settings';
import { BooleanTag, DataList, DataListItem, Module, ModuleContent, ModuleHeader, Time } from '../../shared';
import {
  AccountMetrics,
  formatLimitAsString,
  getUsagePercentage,
  hasSubscription,
  isHostBasedPlan,
  isPlanWithSSO,
  LegacySubscription,
  MetricType,
  PlanType,
  Subscription,
  SubscriptionState,
  SubscriptionStateDescriptions,
  SubscriptionStateReadableNames,
} from '../model';
import { SubscriptionDateManager } from './SubscriptionDateManager';
import { SubscriptionUsageMeter } from './SubscriptionUsageMeter';

type SubscriptionAccountDetailsProps = Readonly<{
  settings: Settings;
  scopes: Scopes;
  subscription: Subscription;
  legacySubscription: LegacySubscription;
  accountRequireMfa: boolean;
  onChangeTrialEnd: (date: Date) => void;
  onChangeGraceEnd: (date: Date) => void;
  subscriptionUsage: AccountMetrics;
  onChangeEnterpriseCampaignDates(date: Date): void;
}>;

export const SubscriptionAccountDetails = ({
  settings,
  scopes,
  subscription,
  legacySubscription,
  accountRequireMfa,
  onChangeGraceEnd,
  onChangeTrialEnd,
  subscriptionUsage,
  onChangeEnterpriseCampaignDates,
}: SubscriptionAccountDetailsProps) => {
  const seatUsagePercentage = getUsagePercentage(
    subscriptionUsage[MetricType.SEATS],
    subscription._limits[MetricType.SEATS],
  );

  let enforceSeatLimits = false;
  if (legacySubscription && legacySubscription._limits) {
    enforceSeatLimits = legacySubscription._limits.enforceSeatLimits;
  }

  return (
    <Module>
      <ModuleHeader>Subscription</ModuleHeader>
      <ModuleContent snug>
        {subscription && (
          <DataList>
            <DataListItem>
              <Flex alignItems="center">
                <Box width={1 / 4}>Seat count</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter
                    ratio={getUsagePercentage(
                      subscriptionUsage[MetricType.SEATS],
                      subscription._limits[MetricType.SEATS],
                    )}
                  />
                </Box>
                <Box ml="auto">
                  {summarizeSubscriptionUsage(
                    subscriptionUsage[MetricType.SEATS],
                    subscription._limits[MetricType.SEATS],
                  )}{' '}
                  {seatUsagePercentage > 1 &&
                    seatUsagePercentage !== Infinity && (
                      <Tooltip content="You're over your seat count limit!">
                        <Icon icon="warning-sign" title={null} color="red" />
                      </Tooltip>
                    )}
                </Box>
              </Flex>
            </DataListItem>
            {!isHostBasedPlan(subscription.planType) && (
              <DataListItem>
                <Flex justify="space-between">
                  <Box>Enforce seat count</Box>
                  <Box>
                    <Tooltip content="When enabled, account is prevented from creating new users above their seat count">
                      <BooleanTag value={enforceSeatLimits} />
                    </Tooltip>
                  </Box>
                </Flex>
              </DataListItem>
            )}
            {isHostBasedPlan(subscription.planType) && (
              <DataListItem>
                <Flex alignItems="center">
                  <Box width={1 / 4}>Hosts</Box>
                  <Box width={2 / 4} />
                  <Box ml="auto">{'Limit: ' + formatLimitAsString(subscription._limits[MetricType.HOSTS])} </Box>
                </Flex>
              </DataListItem>
            )}
            <DataListItem>
              <Flex alignItems="center">
                <Box width={1 / 4}>Client-side MAUs</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter
                    ratio={getUsagePercentage(
                      subscriptionUsage[MetricType.MONTHLY_ACTIVE_USERS],
                      subscription._limits[MetricType.MONTHLY_ACTIVE_USERS],
                    )}
                  />
                </Box>
                <Box ml="auto">
                  {summarizeSubscriptionUsage(
                    subscriptionUsage[MetricType.MONTHLY_ACTIVE_USERS],
                    subscription._limits[MetricType.MONTHLY_ACTIVE_USERS],
                  )}{' '}
                  {getUsagePercentage(
                    subscriptionUsage[MetricType.MONTHLY_ACTIVE_USERS],
                    subscription._limits[MetricType.MONTHLY_ACTIVE_USERS],
                  ) > 1 && (
                    <Tooltip content="You're over your MAU limit!">
                      <Icon icon="warning-sign" title={null} color="red" />
                    </Tooltip>
                  )}
                </Box>
              </Flex>
            </DataListItem>
            {!settings.removeServerConnections && (
              <DataListItem>
                <Flex alignItems="center">
                  <Box width={1 / 4}>Server connections</Box>
                  <Box width={2 / 4}>
                    <SubscriptionUsageMeter
                      ratio={getUsagePercentage(
                        subscriptionUsage[MetricType.SERVER_CONNECTIONS],
                        subscription._limits[MetricType.SERVER_CONNECTIONS],
                      )}
                    />
                  </Box>
                  <Box ml="auto">
                    {summarizeSubscriptionUsage(
                      subscriptionUsage[MetricType.SERVER_CONNECTIONS],
                      subscription._limits[MetricType.SERVER_CONNECTIONS],
                    )}{' '}
                    {getUsagePercentage(
                      subscriptionUsage[MetricType.SERVER_CONNECTIONS],
                      subscription._limits[MetricType.SERVER_CONNECTIONS],
                    ) > 1 && (
                      <Tooltip content="You're over your server connections limit!">
                        <Icon icon="warning-sign" title={null} color="red" />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </DataListItem>
            )}
            {subscription._limits[MetricType.EVENTS_RECEIVED] !== 0 && (
              <DataListItem>
                <Flex alignItems="center">
                  <Box width={1 / 4}>Experimentation events</Box>
                  <Box width={2 / 4}>
                    <SubscriptionUsageMeter
                      ratio={getUsagePercentage(
                        subscriptionUsage[MetricType.EVENTS_RECEIVED],
                        subscription._limits[MetricType.EVENTS_RECEIVED],
                      )}
                    />
                  </Box>
                  <Box ml="auto">
                    {summarizeSubscriptionUsage(
                      subscriptionUsage[MetricType.EVENTS_RECEIVED],
                      subscription._limits[MetricType.EVENTS_RECEIVED],
                    )}{' '}
                    {getUsagePercentage(
                      subscriptionUsage[MetricType.EVENTS_RECEIVED],
                      subscription._limits[MetricType.EVENTS_RECEIVED],
                    ) > 1 && (
                      <Tooltip content="You're over your experimentation events limit!">
                        <Icon icon="warning-sign" title={null} color="red" />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </DataListItem>
            )}
            {subscription._limits[MetricType.EXPERIMENTATION_KEYS] !== 0 && (
              <DataListItem>
                <Flex alignItems="center">
                  <Box width={1 / 4}>Experimentation keys</Box>
                  <Box width={2 / 4} />
                  <Box ml="auto">
                    {'Limit: ' + formatLimitAsString(subscription._limits[MetricType.EXPERIMENTATION_KEYS])}{' '}
                    <Tooltip content="For Experimentation Keys usage, use Looker">
                      <Icon icon="info-sign" title={null} color={Colors.GRAY1} />
                    </Tooltip>
                  </Box>
                </Flex>
              </DataListItem>
            )}
            {subscription._limits[MetricType.EVENTS_PUBLISHED] !== 0 && (
              <DataListItem>
                <Flex alignItems="center">
                  <Box width={1 / 4}>Events published</Box>
                  <Box width={2 / 4}>
                    <SubscriptionUsageMeter
                      ratio={getUsagePercentage(
                        subscriptionUsage[MetricType.EVENTS_PUBLISHED],
                        subscription._limits[MetricType.EVENTS_PUBLISHED],
                      )}
                    />
                  </Box>
                  <Box ml="auto">
                    {summarizeSubscriptionUsage(
                      subscriptionUsage[MetricType.EVENTS_PUBLISHED],
                      subscription._limits[MetricType.EVENTS_PUBLISHED],
                    )}{' '}
                    {getUsagePercentage(
                      subscriptionUsage[MetricType.EVENTS_PUBLISHED],
                      subscription._limits[MetricType.EVENTS_PUBLISHED],
                    ) > 1 && (
                      <Tooltip content="You're over your data export limit!">
                        <Icon icon="warning-sign" title={null} color="red" />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </DataListItem>
            )}
            <DataListItem>
              <Flex justify="space-between">
                <Box>Subscription state</Box>
                <Box>
                  {SubscriptionStateReadableNames[subscription.state]}{' '}
                  <Tooltip content={SubscriptionStateDescriptions[subscription.state]}>
                    <Icon icon="info-sign" title={null} color={Colors.GRAY1} />
                  </Tooltip>
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>Plan name</Box>
                <Box>
                  {subscription.planType !== null ? subscription.planType : 'N/A'} {renderVersion(subscription)}
                </Box>
              </Flex>
            </DataListItem>
            {subscription.billingInterval && (
              <DataListItem>
                <Flex justify="space-between" align="center">
                  <Box>Billing interval</Box>
                  <Box>
                    <Flex align="center">
                      {subscription.billingInterval === 'MonthlyBilling' ? 'Monthly' : 'Annual'}
                    </Flex>
                  </Box>
                </Flex>
              </DataListItem>
            )}
            <DataListItem>
              <Flex justify="space-between">
                <Box>MFA Required</Box>
                <Box>
                  <BooleanTag value={accountRequireMfa} />
                </Box>
              </Flex>
            </DataListItem>
            {isPlanWithSSO(subscription.planType) && (
              <DataListItem>
                <Flex justify="space-between">
                  <Box>SSO Add-on</Box>
                  <Box>
                    <BooleanTag value={subscription._limits.SSO === 1} />
                  </Box>
                </Flex>
              </DataListItem>
            )}
            <DataListItem>
              <Flex justify="space-between">
                <Box>Trial</Box>
                <Box>
                  <BooleanTag value={subscription.state === SubscriptionState.ACTIVE_TRIAL} />
                </Box>
              </Flex>
            </DataListItem>
            {subscription.trialExtensionCount > 0 && (
              <DataListItem>
                <Flex justify="space-between">
                  <Box>Trial extension count</Box>
                  <Box>{subscription.trialExtensionCount}</Box>
                </Flex>
              </DataListItem>
            )}
            {subscription.campaigns &&
              subscription.campaigns.customerEnterpriseCampaign && (
                <DataListItem>
                  <Flex justify="space-between">
                    <Box>
                      Self-serve Enterprise trial period{' '}
                      <Tooltip content="Customer started an Enterprise trial from within the application">
                        <Icon icon="info-sign" title={null} color={Colors.GRAY1} />
                      </Tooltip>
                    </Box>
                    <Box>
                      <Flex align="center">
                        <Time datetime={subscription.campaigns.customerEnterpriseCampaign.startDateTime} format="L" />
                        —
                        <Time datetime={subscription.campaigns.customerEnterpriseCampaign.endDateTime} format="L" />
                      </Flex>
                      {settings.enableExtendingEnterpriseTrialCampaign && (
                        <SubscriptionDateManager
                          value={subscription.campaigns.customerEnterpriseCampaign.endDateTime}
                          onChange={onChangeEnterpriseCampaignDates}
                        />
                      )}
                    </Box>
                  </Flex>
                </DataListItem>
              )}
            {subscription.state !== SubscriptionState.ACTIVE_SUBSCRIPTION && (
              <DataListItem>
                <Flex justify="space-between" align="center">
                  <Box>Trial period</Box>
                  <Box>
                    <Flex align="center">
                      <Time datetime={subscription.trialStartDate} format="L" />
                      —
                      <Time datetime={subscription.trialEndDate} format="L" />
                      {scopes.extendTrialGrace && (
                        <SubscriptionDateManager value={subscription.trialEndDate} onChange={onChangeTrialEnd} />
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </DataListItem>
            )}
            <DataListItem>
              <Flex justify="space-between" align="center">
                <Box>Grace period</Box>
                <Box>
                  <Flex align="center">
                    {subscription.graceStartDate ? <Time datetime={subscription.graceStartDate} format="L" /> : 'N/A'}—
                    {subscription.graceEndDate ? <Time datetime={subscription.graceEndDate} format="L" /> : 'N/A'}
                    {scopes.extendTrialGrace && (
                      <SubscriptionDateManager value={subscription.graceEndDate} onChange={onChangeGraceEnd} />
                    )}
                  </Flex>
                </Box>
              </Flex>
            </DataListItem>

            <DataListItem>
              <Flex justify="space-between" align="center">
                <Box>Billing period</Box>
                <Box>
                  <Flex align="center">
                    {hasSubscription ? <Time datetime={subscription.usagePeriodStartDate} format="L" /> : 'N/A'}—
                    {hasSubscription ? <Time datetime={subscription.usagePeriodEndDate} format="L" /> : 'N/A'}
                  </Flex>
                </Box>
              </Flex>
            </DataListItem>
            {subscription.subscriptionType &&
              subscription.subscriptionType !== 'Standard' && (
                <DataListItem>
                  <Flex justify="space-between" align="center">
                    <Box>Subscription Type</Box>
                    <Box>
                      <Flex align="center">{subscription.subscriptionType}</Flex>
                    </Box>
                  </Flex>
                </DataListItem>
              )}
          </DataList>
        )}
      </ModuleContent>
    </Module>
  );
};

const renderVersion = (subscription: Subscription) => {
  if (subscription.planType !== PlanType.ENTERPRISE && subscription.state === SubscriptionState.ACTIVE_SUBSCRIPTION) {
    return `(version ${subscription.version !== null ? subscription.version : 0})`;
  } else {
    return '';
  }
};

export const summarizeSubscriptionUsage = (subscriptionUsage: number, subLimit: number) => {
  let percentage;
  if (!subLimit) {
    percentage = `(${subscriptionUsage * 100}%)`;
  } else {
    percentage = `(${subLimit === -1 ? 0 : Math.round(getUsagePercentage(subscriptionUsage, subLimit) * 100)}%)`;
  }
  return `${numbro(subscriptionUsage).format({
    thousandSeparated: true,
  })} / ${subLimit === -1 ? '∞' : numbro(subLimit).format({ thousandSeparated: true })} ${percentage}`;
};
