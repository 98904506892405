import { Alert, Button, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Account } from '../model';
type CancelPlanButtonProps = Readonly<{
  onCancelSubscription: (account: Account) => void;
  account: Account;
}>;
type CancelPlanButtonState = Readonly<{
  isOpen: boolean;
}>;

export class CancelPlanButton extends React.PureComponent<CancelPlanButtonProps, CancelPlanButtonState> {
  public state: CancelPlanButtonState = {
    isOpen: false,
  };
  public render() {
    const { onCancelSubscription, account } = this.props;
    return (
      <>
        <Button intent={Intent.DANGER} onClick={() => this.setState({ isOpen: true })}>
          Cancel Plan
        </Button>
        <Alert
          isOpen={this.state.isOpen}
          cancelButtonText="Don't cancel plan"
          confirmButtonText="Cancel plan"
          intent={Intent.DANGER}
          onCancel={() => this.setState({ isOpen: false })}
          onConfirm={() => this.handleConfirm(onCancelSubscription, account)}
        >
          Are you REALLY sure you want to cancel this plan?
        </Alert>
      </>
    );
  }

  private handleConfirm(onCancelSubscription: (account: Account) => void, account: Account) {
    onCancelSubscription(account);
    this.setState({ isOpen: false });
  }
}
